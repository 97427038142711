.task-card-center-div {
  width: 50%;
  margin: auto;
}

.task-update-button:hover button{
  display: block;
}

.task-update-button button{
  display: none;
}

.task-update-button {
  display: flex;
  flex-direction: row;
  /* margin: 10px 0; */
  /* border-bottom: 1px solid lightgrey; */
  padding: 20px 0;
}

.task-card-center-div-empty {
  text-align: center;
}

.section-column-overdiv {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.project-top-name-div,
.project-name-div {
  width: 50%;
  margin: auto;
}

.section-center-div {
  width: calc(100% - 330px);
  margin: 0 330px;

}

.section-map-div {
  margin: 0 10px;
}

.add-task-button-empty-list-div {
  position: relative;
  width: fit-content;
  right: -35.5%;
}

.section-droppable-div {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  margin-top: 20px;
}
