.projects-button-actual {
  background-color: white;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  color: white;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 5px 10px;
  margin-left: 15px;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.projects-button-actual:hover {
  background-color: whitesmoke;
  color: #d1453b;
}

.project-update-button:hover button{
  display: block;
}

.project-update-button button{
  display: none;
}

.user-control-dropdown {
  border-right: 2px solid #d1453b;
  position: fixed;
  top: 33px;
  left: -1px;
  height: 100%;
  width: 200px;
  padding: 40px 50px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  gap: 15px;

  z-index: 1;

  transition: left 0.2s ease-in;
}

/* @keyframes slidein {
  0% {
    left: -300px;
  }
  100% {
    left: -1px
  }
}

@keyframes slideout {
  0% {
    left: -1px
  }
  100% {
    left: -300px;
  }
} */

.user-control-dropdown-false {
  border-right: 2px solid #d1453b;
  position: fixed;
  top: 33px;
  left: -302px;
  height: 100%;
  width: 200px;
  padding: 40px 50px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  gap: 15px;

  z-index: 1;

  transition: left 0.2s ease-in;
}

.user-control-login-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.user-control-login-buttons button {
  background-color: white;
  border: none;
  cursor: pointer;
  font-weight: 900;
  color: white;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid white;
  padding: 5px 10px;
  margin-left: 15px;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.user-control-login-buttons button:hover {
  background-color: whitesmoke;
  color: #d1453b;
}

.create-project-button-div {
  position: relative;
  left: 100px;
}

.create-project-button-div button {
  background-color: white;
  border: 1px solid whitesmoke;
  cursor: pointer;
  font-size: 0.9em;
  color: black;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 10px;

  transition:
    background-color 0.2s ease-out,
    border 0.2s,
    color 0.2s ease-out;
}

.create-project-button-div button:hover {
  background-color: whitesmoke;
  color: #d1453b;
  border: 1px solid #d1453b;
}

.projects-title-and-add-button-div {
  height: 70px;
  height: 32px;
}

.projects-today-link {
  margin: 15px 0;
  padding: 5px 0;
  border-radius: 7px;
  background-color: whitesmoke;
  transition: background-color 0.2s ease-in;
}

.projects-today-link:hover {
  margin: 15px 0;
  padding: 5px 0;
  border-radius: 7px;
  background-color: lightgrey;
}

.project-today-svg-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}
