/* OVERDIV */

.section-form-overdiv {
  min-width: 250px;
}

/* CREATE FORM */

.section-title-create-button {
  display: flex;
  flex-direction: row;

  border: none;
  border-radius: 4px;
  font-size: 95%;
  font-weight: 400;
  background-color: whitesmoke;
  color: grey;
}

.section-title-create-button:hover {
  color: #d1453b;
}

.section-form-create-button-svg {
  margin: 5px 40px 5px 20px;
}

.section-form-create-button-div {
  padding-right: 80px;
  float: right;
  margin-top: 8px;
}


/* UPDATE FORM */

.section-title-edit-button {
  border: none;
  border-radius: 4px;
  max-height: 30px;
  font-size: large;
  font-weight: 400;
  cursor: text;
  background-color: white;
}

.section-title-edit-button:hover {
  background-color: whitesmoke;
}

.section-name-input-div {
  border: 1px solid black;
  border-radius: 4px;
  font-size: large;
  font-weight: 400;
}

.section-form-buttons {
  display: flex;
  flex-direction: row;
}

.section-form-submit-button {
  border: none;
  background-color: #d1453b;
  cursor: pointer;
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  margin-top: 3px;
  margin-right: 3px;
}

.section-form-submit-button:hover {
  background-color: #b1382f;
}

.section-form-submit-button:disabled {
  background-color: grey;
  cursor: default;
}

.section-form-cancel-button {
  border: none;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  color: black;
  padding: 6px 10px;
  border-radius: 4px;
  margin-top: 3px;
}

.section-form-cancel-button:hover {
  background-color: rgb(218, 218, 218);
}

.section-form-delete-button {
  float: right;
}

.section-form-delete-button button {
  border: none;
  background-color: transparent;
  padding: 7px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.section-form-delete-button button:hover {
  background-color: #d1453b;
  color: white;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.section-form-delete-button button:active {
  background-color: #992f28;
  color: white;

  transition: none;
}
