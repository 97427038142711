.task-card-card-label-span {
  padding: 5px 10px;
  border: 1px solid lightgrey;
  border-radius: 7px;
  margin: 10px 5px;
}

.task-card-card-overdiv {
  width: 100%;
  min-width: 300px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding: 10px;
}

.task-card-card-overdiv div {
  margin: 6px 0;
}

.task-card-card-first-row-div {
  margin: 6px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.task-card-card-name-div {
  width: 100%;
}

.task-card-card-first-row-div button {
  top: 50%;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
}

.task-card-card-first-row-div button:hover {
  background-color: #d1453b;
  color: white;
  border-radius: 7px;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.task-card-card-button-container {
  display: flex;
  flex-direction: row;
}

.task-card-card-description-div,
.task-card-card-label-div {
  padding: 8px 0;
  /* border: 1px solid green; */
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.task-card-card-label-div {
  padding-bottom: 22px;
}
