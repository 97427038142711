.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.whole-ass-nav-bar {
  position: sticky;
  top: 0;
  background-color: #d1453b;
  padding: 10px 0;
}

.nav-bar-top-span {
  display: flex;
  flex-direction: row;
}

.profile-buttons-div {
  width: 100%;
  overflow: hidden;
}

.profile-buttons-div button {
  float: right;
  background-color: white;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  color: white;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 5px 10px;
  margin-right: 15px;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.profile-buttons-div button:hover {
  background-color: whitesmoke;
  color: #d1453b;
}

.DueTo-words-actual {
  font-size: x-large;
  color: white;
  padding: 4px 7px;
  border-radius: 10px;
  margin: 0 10px;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.DueTo-words-actual:hover {
  background-color: whitesmoke;
  color: #d1453b;
}

#logged-in-user-menu-div {
  border: 2px solid #d1453b;
  background-color: whitesmoke;
  padding: 60px 30px;
  position: absolute;
  right: 0;
  top: 47px;
}

#logged-in-user-menu-div div {
  padding: 10px 0;
}

.log-out-button button {
  background-color: transparent;
  color: black;
  border-color: black;
}

.log-out-button button:hover {
  background-color: #d1453b;
  color: white;
  border-color: #d1453b;
}

/* @keyframes buttonFadeIn {
  0% {
    background-color: transparent;
  }

  100%{
    background-color: whitesmoke;
  }
}

@keyframes buttonFadeOut {
  0% {
    background-color: whitesmoke;
  }

  100%{
    background-color: transparent;
  }
}

@keyframes wordFadeIn {
 0% {
  color: white;
 }

 100% {
  color: rgb(255, 113, 113);
 }
}

@keyframes wordFadeOut {
  0% {
   color: rgb(255, 113, 113);
  }

  100% {
   color: white;
  }
 } */
