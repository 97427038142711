.project-form-modal-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.project-form-modal-buttons button {
  border: none;
  cursor: pointer;
  font-weight: 900;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #d1453b;
  padding: 5px 10px;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
}

.project-form-modal-buttons button:hover {
  background-color: #d1453b;
  color: white;
}
